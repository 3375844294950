.form-container {
  text-align: center;
  width: 90%;
  margin: 0 5%;
}

.form-container h1 {
  font-size: 26px;
  color: 'black';
  text-align: center;
}

.form {
  text-align: left;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type='text'],
input[type='email'],
textarea {
  width: 100%;
  min-height: 25px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.attach-label {
  float: left;
  position: relative;

}


::file-selector-button {
  display: none;
}

input[type=file] {
  display: none;
}

button[type='submit'] {
  background-color: rgb(126, 6, 177);
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  display: block;
  margin: 0 auto;
  margin-top: 20px;
}


.attach-label {
  display: flex;
  align-items: center;
}

.attach-icon {
  margin-right: 10px;
}

.attachment-text {
  float: right;
}

.recaptcha-text {
  display: block;
  text-align: center;
  margin-top: 50px;
}