.solutions-category .LineHeader {
  font-size: 40px;
  color: var(--purple);
  font-weight: bold;
}

.solutions-subcategory .LineHeader {
  font-size: 30px;
  color: var(--purple);
  font-weight: bold;
}

.solutions-category-container {
  display: flex;
  flex-direction: column;
}

.solutions-subcategory {
  margin-top: 0px;
  display: flex;
  flex-direction: column;
}

.solutions-subcategory-container {
  margin: 0px 40px 0px 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.solution-description {
  flex: 1;
  font-size: 18px;
  text-align: center;
  color: #666;
  margin: 20px;
}

.solution-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  box-sizing: border-box;
}

.solution-card {
  flex-basis: 30%;
  box-sizing: border-box;
  border-radius: 40px;
  margin: 10px;
  padding: 20px 5px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: black;
  background-color: var(--purple_lightest);
}

.solution-card:hover {
  background-color: var(--purple_light);
}

.solution-icon {
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.solution-image {
  max-height: 128px;
}

.solution-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.solution-name {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;
}

.solution-type {
  text-align: center;
}

@media (max-width: 768px) {
  .solution-card {
    flex-basis: 45%;
    margin-bottom: 20px;
  }
}

@media (max-width: 576px) {
  .solution-card {
    flex-basis: 90%;
  }
}

.horizontal-divider {
  width: 100%;
  height: 0.1px;
  background-color: #ccc;
  margin: 10px 0;
}

.bottom-text {
  font-size: 14px;
  color: #666;
  margin-top: 50px;
}

/* Responsive Styles */

@media (max-width: 768px) {
  .solution-card {
    flex-basis: 45%;
  }
}

@media (max-width: 480px) {
  .solution-list {
    margin: 120px 10px 100px 10px;

  }

  .solution-card {
    flex-basis: 100%;
  }
}