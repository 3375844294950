.OurStory {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
}

.OurStoryContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: stretch;
}

.OurStoryContent {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
}

.StoryContent {
  flex-basis: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  text-align: center;
  margin: 0;
}

.StoryContent h1 {
  font-size: 35px;
  color: var(--purple);
}

.StoryContent p {
  flex: 1;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin: 3px 0;
  font-size: 20px;
  background-color: var(--purple_lightest);
  border-radius: 80px;
  padding: 10px 40px;
}

.VideoContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  border-radius: 20px;
  background-color: black;
}

.Video {
  flex: 1;
  margin: 20px 0;
  border: 0;
}

@media (max-width: 1100px) {
  .OurStoryContent {
    flex-direction: column;
  }

  .StoryContent {
    flex: 1;
    margin: 0 5%;
  }

  .VideoContainer {
    border-radius: 80px;
    min-height: 60vw;
  }

  .Video {
    margin: 20px;
    border-radius: 20px;
  }
}


