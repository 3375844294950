.Hiring {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
}

.hiringPageHeader {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
}

.ProgramContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: stretch;
}

.Program {
  flex-basis: 40%;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  color: black;
  background-color: var(--purple_lightest);
  border-radius: 80px;
  padding: 20px;
  text-align: center;
}

.Program h2 {
  color: var(--purple);
}

@media (max-width: 768px) {
  .Program {
    flex-basis: 90%;
    margin: 10px 0;
  }
}