.videoWrapper {
  width: 100%;
 align-items: center;
 justify-content: center;
 display: flex;
 flex-direction: column;
}

.landingPageVideo {
  width: 90%;
  margin: 0 5%;
  max-width: 780px;
  border-radius: 50px;
}