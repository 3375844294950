.com-grid-container {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    margin: 0 auto;
    max-width: 1300px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 100px;
  }
  
  .com-grid-item {
    text-align: center;
    width: 100%;
    max-width: 600px;
    margin: 10px;
  }

 
  
  .com-circle {
    width: 100%;
    height: auto;
    max-height: 600px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    margin-bottom: 10px;
  }
  
  .com-text {
    font-size: 18px;
    color: #000;
  }