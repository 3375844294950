.blogListContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  }
  
  .blogItemContainer {
    flex-basis: calc(33.33% - 20px);
    margin: 10px;
  }
  
  @media screen and (max-width: 768px) {
    .blogItemContainer {
      flex-basis: calc(50% - 20px);
    }
  }
  
  @media screen and (max-width: 480px) {
    .blogItemContainer {
      flex-basis: calc(100% - 20px);
    }
  }