.AccessibilityTools {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.AccessibilityTools .LineHeader {
  font-size: 40px;
  color: var(--purple);
  font-weight: bold;
}


.category-list {
  height: 100%;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-evenly;
}

.CategoryLink {
  flex-basis: 30%;
  margin: 10px;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  text-decoration: none;
  color: black;
  background-color: var(--purple_lightest);
}

.CategoryLink:hover {
  background-color: var(--purple_light);
}

.CategoryImage {
  margin: 10px;
  width: 90%;
  max-width: 180px;
  max-height: 180px;
  border-radius: 50%;
}  

.CategoryTitleContainer {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CategoryTitle {
  width: 90%;
}

@media (max-width: 768px) {
  .CategoryLink {
    flex-basis: 45%;
    margin-bottom: 20px;
  }

  .CategoryImage {
    max-width: 150px;
    max-height: 150px;
  }  
  
}

@media (max-width: 576px) {
  .CategoryLink {
    flex-basis: 45%;
  }
  
  .CategoryImage {
    max-width: 100px;
    max-height: 100px;
  }  
  
}