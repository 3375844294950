.OurTeam {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.teamContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: stretch;
}

.Member {
  flex-basis: 20%;
  margin: 10px;
  padding: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 40px;
  text-align: center;
  text-decoration: none;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.Member:hover {
  background-color: #fbf3ff;
}

.Member img {
  flex: 1;
  max-width: 350px;
  border-radius: 50%;
  object-fit: cover;
}

.Member h1,
.Member p {
  margin: 10px 0;
}

.Member .CustomButton {
  margin-top: 10px;
}
  /* Media Queries */
  
  @media (max-width: 768px) {
    
    .Member {
      flex-basis: 45%;
      margin-bottom: 20px;
    }
  }
  
  @media (max-width: 576px) {
    .Member {
      flex-basis: 90%;
    }
  }