.ps-container {
    width: 100%;
    background-color: rgb(126, 6, 177);
    padding: 30px;
    box-sizing: border-box;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
 
    
  }
  
  .ps-header {
    color: white;
  }
  
  .ps-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
  }
  
  .ps-box {
    width: calc(50% - 15px);
    background-color: white;
    padding: 20px;
    border-radius: 30px;
    text-align: center;
    margin-bottom: 30px;
    color: black;
    text-decoration: none;
    transition: background-color 0.3s;
    
  }
  
  .ps-box:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }