.consentContent {
    max-width: 800px; /* Set the maximum width of the container */
    margin: 0 auto; /* Center the container horizontally */
    padding: 20px; /* Add padding for better readability */
    padding-top: 100px;
    /* Add responsive styles */
    @media (max-width: 600px) {
      font-size: 14px; /* Adjust font size for smaller screens */
    }
    color: rgb(94, 94, 94);
    margin-bottom: 100px;
  }