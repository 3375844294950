.AboutUs {
  width: 100%;
  display: flex;
  padding: 0 0 20px 0;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: var(--purple);
}

.AboutUs .LineHeader {
  color: white;
  height: 70px;
}

.AboutUs .LineHeaderLine {
  background-color: white;
}

.AboutUsItems {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.AboutItem {
  flex: 1;
  margin: 0 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  border: 1px solid white;
  border-radius: 50px;
  color: white;
  text-decoration: none;
}

.AboutItem p {
  font-size: 30px;
  font-weight: 700;
  line-height: 1.5;
  margin: 0;
}

@media (max-width: 768px) {
  .AboutUsItems {
    flex-direction: column;
    align-items: center;
  }

  .AboutItem {
    margin: 20px;
    width: 60%;
  }
}


