.Community {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.CommunityContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.CommunityContainer h1 {
  text-align: center;
  color: var(--purple);
}

.PersonList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.PersonTile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  padding: 30px 10px;
  text-decoration: none;
  color: #000;
  background-color: var(--purple_lightest);
  border-radius: 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.PersonTile:hover {
  background-color: var(--purple_light);
}

.PersonPicture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}



.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  /* Add desired margin if necessary */
}

.previous-button,
.next-button {
  padding: 10px 20px;
  background-color: var(--purple);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.previous-button:hover,
.next-button:hover {
  background-color: var(--purple_light);
}

.page-number {
  margin: 0 10px;
}

.becomeMemberContainer {
  width: 60%;
  border-radius: 160px;
  margin: 20px 20%;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:stretch;
  text-align: center;
  color: var(--purple);
  background: var(--purple_lightest);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.becomeMemberContainer h1 {
  margin: 0;
  color: var(--purple);
  font-size: 32px;
}

.becomeMemberContainer p {
  width: 80%;
  text-align: center;
  font-size: 22px;
  margin: 15px 10%;
}

.becomeMemberContainer form {
  margin: 0 20%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.becomeMemberContainer input[type='email'] {
  padding: 10px;
  margin-right: 10px;
}

.BecomeMemberButton {
  margin: 0;
  padding: 10px 20px;
  width: 80px;
  color: white;
  text-decoration: none;
  border: 1px solid white;
  border-radius: 18px;
  background-color: var(--purple);
}

@media screen and (max-width: 768px) {
  .becomeMemberContainer {
    width: 95%;
    border-radius: 160px;
    margin: 20px 0;
  }
  
  
}