.AccessibilityPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.AccessibilityPage .LineHeader {
  font-size: 40px;
  color: var(--purple);
  font-weight: bold;
}

.tools-header,
.apps-header {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  color: var(--purple);

}

.tool-app-description {
  flex: 1;
  font-size: 18px;
  text-align: center;
  color: #666;
  margin: 20px;
}

.tool-app-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  box-sizing: border-box;
}

.tool-app-card {
  flex-basis: 30%;
  box-sizing: border-box;
  border-radius: 40px;
  margin: 10px;
  text-decoration: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: black;
  background-color: var(--purple_lightest);
}

.tool-app-card:hover {
  background-color: var(--purple_light);
}

@media (max-width: 768px) {
  .tool-app-card {
    flex-basis: 45%;
    margin-bottom: 20px;
  }
}

@media (max-width: 576px) {
  .tool-app-card {
    flex-basis: 90%;
  }
}

.tool-app-image {
  width: 90%;
  border-radius: 40px;
  margin: 20px;
}

.tool-app-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.tool-app-name {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
}

.tool-app-type {
  font-size: 18px;
  color: #666;
  margin-bottom: 5px;
}


.horizontal-divider {
  width: 98%;
  height: 0.1px;
  background-color: #ccc;
  margin: 10px 0;
}

