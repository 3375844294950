.navbar {
  width: 100%;
  background-color: white;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.navbarWide {
  width: 98%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}

.navbarTight {
  display: none;
  width: 100%;
  height: 100%;
}

.up {
  width: 98%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}

.center {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
}

.down {
  display: flex;
  width: 100%;
}

.menu-toggle {
  font-size: 30px;
}

.navbar-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 100px;
}

.topLeft {
  display: flex;
  align-items: center;
  justify-content: center;
}

.topRight {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-menu {
  padding-inline-start: 0px;
  margin-block-start: 0;
  margin-block-end: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  list-style: none;
  justify-content: space-evenly;
}

.nav-item {
  flex: 1;
  display: flex;
  flex: row;
  align-items: center;
}

.nav-links {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: black;

}

.nav-links:hover {
  color: var(--purple);
}

.dropdownIcon {
  margin-left: 5px;
  font-size: 14px;
  transition: transform 0.3s ease-in-out;
  color: black
}

.dropdownIcon.rotate {
  transform: rotate(180deg);
}


@media (max-width: 960px) {
  .navbar {
    height: auto;
  }

  .navbarWide {
    display: none;
  }
  
  .navbarTight {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--purple);
  }

  .nav-menu {
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-evenly;
    font-size: 30px;
    text-align: center;
  }

  .nav-item {
    margin: 10px 0;
  }
  
.nav-item-horizontal {
  font-size: 22px;
}

  .nav-links {
    width: 100%;
    height: 100%;
    color: white;
  }

  .nav-links:hover {
    color: var(--purple_light);
  }

  .dropdownIcon {
    color: white;
  }
  
}