.bottom-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    margin: 0 10vw;
  }
  
  .left-content {
    display: flex;
    align-items: center;
  }
  
  .right-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .right-content a {
    margin-left: 10px;
  }
  
  .right-content a:hover {
    color: #bbb;
  }
  
  @media (max-width: 600px) {
    .bottom-bar {
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
    }
  
    .left-content,
    .right-content {
      width: 100%;
      text-align: center;
    }
  
    .right-content {
      margin-top: 10px;

    }
  
    .right-content a {
      margin-left: 0;
      margin-right: 10px;

    }
  }