.my-button {
    display: inline-block;
    padding: 10px 20px;
    border: 1px solid rgb(126, 6, 177);
    border-radius: 20px;
    color: rgb(126, 6, 177);
    background-color: #fff;
    transition: all 0.3s ease;
    font-size: 12px;
    margin-top: 20px;
    text-decoration: none;
    font-weight: 600;
  }
  
  .my-button:hover {
    color: #fff;
    background-color: rgb(126, 6, 177);
  }
  
  .reverse-button {
    display: inline-block;
    padding: 10px 20px;
    border: 1px solid #fff;
    border-radius: 20px;
    color:  #fff;
    background-color: rgb(126, 6, 177);
    transition: all 0.3s ease;
    font-size: 12px;
    margin-top: 20px;
    text-decoration: none;
    font-weight: 600;
  }
  
  .reverse-button:hover {
    color: rgb(126, 6, 177);
    background-color:  #fff;
  }