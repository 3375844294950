.Cases {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;
}

.CaseContainer {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-evenly;
    width: 100%;
}

.CaseTile {
    flex-basis: 40%;
    margin: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    color: #000;
    background-color: var(--purple_lightest);
    border-radius: 40px;
}

.CaseTile .InlineMarkdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.CaseTile:hover {
    background-color: var(--purple_light);
}

.CaseTile h1 {
    font-size: 26px;
}