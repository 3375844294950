.card {
  display: inline-block;
  width: 250px; /* Adjust the width as desired */
  padding: 20px;
  margin: 10px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}



.card-title {
  font-size: 14px;
  font-weight: bold;
  color: 0;
  margin-top: 10;
  color: #000;

}

.card-date {
  font-size: 14px;
  color: #000;
}