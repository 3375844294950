.InternRole {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.InternRoleContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.InternRoleContent {
  flex: 1;
  margin: 0 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.InternRoleContent p {
  width: 100%;
  margin: 5px 0;
}

.InternRoleContent ul {
  width: 100%;
  margin: 5px 0;
}

.InternForm {
  width: 90%;
  margin: 0 5%;
}