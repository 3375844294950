.downloadcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.downloadrow {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

}

.downloadcolumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
  padding: 20px;
  background-color: white;
}

.downloadImg {
  width: 40%;
  max-width: 550px;
  height: auto;
}

@media screen and (max-width: 768px) {
  .downloadImg {
    width: 90%;
  }
}