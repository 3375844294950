.LatestArticles {
  background-image: linear-gradient(rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.24) 100%), url(//img1.wsimg.com/isteam/ip/7890b365-6a9d-4d1a-a384-18a6952c788c/Pastel%20Minimalist%20Self%20Care%203%20Venn%20Dia-1462f6b.png/:/cr=t:0%25,l:0%25,w:100%25,h:100%25/rs=w:1023,m);
  background-size: cover;
  background-position: center;
  margin: 2% 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.LatestArticlesTitle {
  font-size: 3rem;
  color: white;
  text-align: center;
  margin: 0;

}

.ArticleList {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;
  margin: 2% 5%;
}

.ArticleLink {
  flex: 1;
  margin: 2%;
  text-decoration: none;
  color: black;
}

.Article {
  background-color: white;
  padding: 40px 0 0 0;
  border-radius: 40px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  text-align: center;
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.Article h3 {
  font-size: 20px;
  font-weight: 400;
  margin: 12px;
}

.Article img {
  width: 26vw;
  height: 26vw;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .ArticleList {
    flex-direction: column;
  }

  .Article img {
    width: 70vw;
    height: 70vw;
  }
  
}