.LineHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  margin: 20px 5%;
  font-size: 40px;
  color: var(--purple);
  font-weight: bold;
}

.LineHeaderLine {
  flex: 1;
  height: 1px;
  background-color: #000000;
}

.LineHeaderTitleContainer {
  display: flex;
  margin: 0 5%;
  justify-content: center;
  align-items: center;
}

.LineHeaderTitle {
  text-align: center;
  margin-block-start: 0;
  margin-block-end: 0;
}

@media screen and (max-width: 768px) {
  .LineHeaderLine {
    display: none;
  }

  .LineHeaderTitleContainer {
    width: 90%;
  }
  
}