.CasePage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.CasePageContainer {
    flex: 1;
    text-align: center;
    width: 95%;
}
.CasePageContainer .InlineMarkdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.CasePageContainer a {
    color: var(--purple);
}