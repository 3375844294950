


    .fullBlogPost {
        max-width: 800px; /* Set the maximum width of the container */
        margin: 0 auto; /* Center the container horizontally */
        padding: 20px; /* Add padding for better readability */
      
        /* Add responsive styles */
        @media (max-width: 600px) {
          font-size: 14px; /* Adjust font size for smaller screens */
        }
      }
      
      