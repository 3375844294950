.Action {
  width: 90%;
  margin: 20px 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 80px;
  background-color: var(--purple);
}

.Action .LineHeader {
  color: white;
  height: 70px;
}

.Action .LineHeaderLine {
  background-color: white;
}

.ActionItems {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.ActionItem {
  flex: 1;
  margin: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  border: 1px solid white;
  border-radius: 50px;
  color: white;
  text-decoration: none;
}

.ActionItem:hover {
  color: var(--purple);
  background-color: var(--purple_light);
  border-color: var(--purple_light);
}

.ActionItem p {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
  margin: 0;
}

@media (max-width: 768px) {
  .ActionItems {
    flex-direction: column;
    align-items: center;
  }

  .ActionItem {
    margin: 20px;
    width: 60%;
  }
}


