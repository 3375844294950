.dropdown-menu {
  background: white;
  width: 200px;
  position: absolute;
  z-index: 2;
  top: 54px;
  list-style: none;
  text-align: start;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-shadow: 0 3px 6px 3px rgba(0, 0, 0, 0.24);
  border-radius: 40px;
  padding: 10px 5px;
  margin: 0;
}

.dropdown-menu li {
  flex: 1;
  display: flex;
  flex: row;
  align-items: center;
}

.dropdown-link {
  display: flex;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: black;
  padding: 8px;
  border-radius: 40px;
  cursor: pointer;
}

.dropdown-sub-link {
  flex: 1;
  height: 100%;
  text-decoration: none;
  text-align: center;
  color: black;
  padding: 8px;
  border-radius: 40px;
  cursor: pointer;
}

.dropdown-title {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  text-align: center;
  color: black;
  padding: 8px;
}

.dropdown-link:hover,
.dropdown-sub-link:hover {
  background-color: var(--purple_light);
  color: var(--purple);
}


@media screen and (max-width: 960px) {
  .fa-caret-down {
    display: none;
  }
}