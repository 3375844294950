.LandingHeader {
  display: flex;
  flex-direction: column;
  justify-Content: center;
  align-items: center;
}

.LandingHeader .LineHeader {
  font-size: 40px;
  color: var(--purple);
  font-weight: bold;
}

.ContentContainer {
  display: flex;
  flex-direction: row;
  justify-Content: center;
  align-items: stretch;
}

.Content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-Content: center;
  align-items: center;
}

.ContentLink {
  width: 90%;
  height: 100%;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--purple_lightest);
  color: var(--purple);
  border-radius: 40px;
}

.ContentLink:hover {
  background-color: var(--purple_light);
}

.Content h3 {
  font-size: 30px;
  text-align: center;
}

.ContentText {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-Content: center;
  align-items: center;
}

.Content p {
  font-size: 16px;
  margin: 0% 5% 5% 5%;
  color: rgb(94, 94, 94);
  line-height: 1.5;
  overflow-wrap: break-word;
  text-align: justify;
}

@media (max-width: 768px) {
  .ContentContainer {
    flex-direction: column;
  }
  
  .Content {
    flex: none;
  }  
  .ContentLink {
    margin: 2% 0%;
  }
}